import React, { useEffect, useRef, useState } from 'react'
import { Form } from '@unform/web'
import Input from 'components/elements/Inputs/Input'
import { FormHandles, SubmitHandler } from '@unform/core/typings/types'
import { trackPromise } from 'react-promise-tracker'
import { AdditionalSignUpAttributes, LoginSteps } from 'lib/tracking/attribution/signupattributes'
import AuthenticationService from 'services/AuthenticationService'
import { ApiError } from 'services/ApiService'
import { ErrorTypes } from 'lib/constants/ErrorTypes'
import { toast } from 'react-toastify'
import NewButton from 'components/elements/NewButton'
import EmailIcon from 'assets/icons/mail.svg'
import { useTranslation } from 'next-i18next'
import { handleFormValidationErrors } from 'lib/validation/form'
import AuthCaptchaDialog from './AuthCaptchaDialog'
import Stack from 'components/elements/Stack'
import { getUploadAlternativeEmail, storeAuthenticationOption } from 'lib/tracking/storage'
import { ButtonText } from 'stories/elements/Typography/Text'
import theme from 'stories/utils/theme'
import { getUsernameOutOfEmail } from 'lib/formatting/auth'

interface FormData {
    email: string
    password: string
    acceptTerms: boolean
}

interface Data {
    name: string
    email: string
    password: string
    acceptTerms: boolean
    twoFactorAuthenticationToken: string | null
    hCaptchaToken: string | null
}

export const loadingAreas = {
    container: 'authEmail',
}

interface Props {
    type: LoginSteps
    additionalSignUpAttributes: AdditionalSignUpAttributes | null
    onClick(): void
    onSuccess(isNewAccount: boolean): void
    disabled?: boolean
    removeMargins?: boolean
    isDark?: boolean
    isSmall?: boolean
    buttonText?: string
    isDarkTheme?: boolean
}

function AuthEmail(props: Props) {
    const formRef = useRef<FormHandles>(null)
    const { t: tCommon } = useTranslation('common')
    const [showCaptchaDialog, setShowCaptchaDialog] = useState(false)
    const [data, setData] = useState<Data | null>(null)
    const [uploadAlternativeEmail, setUploadAlternativeEmail] = useState<string | null>(null)

    useEffect(() => {
        setUploadAlternativeEmail(getUploadAlternativeEmail())
    }, [])

    const handleSubmit: SubmitHandler<FormData> = async (formData) => {
        props.onClick()

        const name = getUsernameOutOfEmail(formData.email)

        storeAuthenticationOption('Email')

        const data: Data = {
            ...formData,
            name,
            twoFactorAuthenticationToken: null,
            hCaptchaToken: null,
        }
        setData(data)
        handle(data)
    }

    const handleCaptchaVerify = (hCaptchaToken: string) => {
        if (!data) return
        setShowCaptchaDialog(false)
        const updatedData: Data = {
            ...data,
            hCaptchaToken,
        }

        setData(updatedData)
        handle(updatedData)
    }

    const handle = (data: Data) => {
        if (props.type === LoginSteps.SignIn) {
            signIn(data.email, data.password, data.twoFactorAuthenticationToken, data.hCaptchaToken)
        } else {
            signUp(data.name, data.email, data.password, data.hCaptchaToken, data.twoFactorAuthenticationToken)
        }
    }

    const signIn = (
        email: string,
        password: string,
        twoFactorAuthenticationToken: string | null,
        hCaptchaToken: string | null
    ) => {
        trackPromise(
            AuthenticationService.signInEmail(email, password, twoFactorAuthenticationToken, hCaptchaToken)
                .then(() => props.onSuccess(false))
                .catch((error) => {
                    if (error instanceof ApiError) {
                        if (error.type === ErrorTypes.InvalidCredentials || error.type === ErrorTypes.FormValidation) {
                            toast.error(tCommon('common/formValidationInvalidCredentialsError'))
                        } else if (error.type === ErrorTypes.Invalid2FAToken) {
                            toast.error(tCommon('common/formValidationInvalid2FAError'))
                        } else if (error.type === ErrorTypes.TooManyLoginAttempts) {
                            toast.error(tCommon('common/apiErrorHandlerTooManyLoginAttemptsError'))
                            return
                        } else if (error.type === ErrorTypes.Requires2FA) {
                            handle2FA()
                        } else if (error.type === ErrorTypes.RequiresCaptcha) {
                            setShowCaptchaDialog(true)
                        } else {
                            error.handleUnknownError(tCommon, 'auth.SignInEmail')
                        }
                    } else {
                        throw error
                    }
                }),
            loadingAreas.container
        )
    }

    const handle2FA = () => {
        const token = window.prompt(tCommon('common/handle2FA'))
        if (!token) {
            return
        }

        if (!data) return
        const updatedData: Data = {
            ...data,
            twoFactorAuthenticationToken: token,
        }
        setData(updatedData)
        handle(updatedData)
    }

    const signUp = async (
        name: string,
        email: string,
        password: string,
        hCaptchaToken: string | null,
        twoFactorAuthenticationToken: string | null
    ) => {
        if (!props.additionalSignUpAttributes) return

        trackPromise(
            AuthenticationService.signUpEmail(name, email, password, props.additionalSignUpAttributes, hCaptchaToken)
                .then(() => props.onSuccess(true))
                .catch((error) => {
                    if (error instanceof ApiError) {
                        if (error.type === ErrorTypes.FormValidation) {
                            handleFormValidationErrors(tCommon, error, formRef.current!)
                        } else if (error.type === ErrorTypes.EmailInUse || error.type === ErrorTypes.EmailAccountExists) {
                            return signIn(email, password, twoFactorAuthenticationToken, hCaptchaToken)
                        } else if (error.type === ErrorTypes.RequiresCaptcha) {
                            setShowCaptchaDialog(true)
                        } else {
                            error.handleUnknownError(tCommon, 'auth.signUpEmail')
                            return
                        }
                    } else {
                        throw error
                    }
                }),
            loadingAreas.container
        )
    }

    return (
        <div>
            <AuthCaptchaDialog show={showCaptchaDialog} onVerify={handleCaptchaVerify} />
            <Form ref={formRef} onSubmit={handleSubmit} initialData={{ email: uploadAlternativeEmail }}>
                <Stack gutter={15}>
                    <div className="inputs">
                        <Input
                            name="email"
                            type="email"
                            label={tCommon('common/authEmailFormEmailLabel')}
                            placeholder={tCommon('common/authEmailFormEmailLabel')}
                            autoComplete="email"
                            autoFocus={!props.removeMargins}
                            noMargin={!!props.removeMargins}
                            height={props.isSmall ? 50 : undefined}
                            darkTheme={props.isDarkTheme}
                        />

                        <Input
                            name="password"
                            type="password"
                            label={tCommon('common/authEmailFormPasswordLabel')}
                            placeholder={tCommon('common/authEmailFormPasswordLabel')}
                            autoComplete={props.type === LoginSteps.SignIn ? 'current-password' : 'new-password'}
                            noMargin={!!props.removeMargins}
                            height={props.isSmall ? 50 : undefined}
                            darkTheme={props.isDarkTheme}
                        />
                    </div>

                    <NewButton
                        loadingArea={loadingAreas.container}
                        fullWidth
                        color={props.isDark ? theme.colors.white : theme.colors.deepSeaBlue}
                        icon={<EmailIcon />}
                        borderRadius={theme.borderRadius.large}
                        height={50}
                        width={300}
                        disabled={!!props.disabled}
                        isWebapp={props.isDarkTheme}
                    >
                        <ButtonText
                            fontFamily="var(--font-inter)"
                            fontWeight={props.isSmall ? 400 : 600}
                            color={props.isDark ? `${theme.colors.body1Black}` : theme.colors.white}
                        >
                            {props.buttonText ?? tCommon('common/continue')}
                        </ButtonText>
                    </NewButton>
                </Stack>
            </Form>
        </div>
    )
}

export default AuthEmail
